import React from 'react';
import Carousel from "react-elastic-carousel";
import {Helmet} from "react-helmet";

import Nav from '../components/Nav';
import Footer from '../components/Footer';
import Contsec from '../components/Contsec';
import Getsec from '../components/Getsec';

import rustV from '../Assets/Images/Sec1 rust vehcle.png';

import icon1 from '../Assets/Icons/WhyIcon1.png';
import icon2 from '../Assets/Icons/WhyIcon2.png';
import icon3 from '../Assets/Icons/WhyIcon3.png';

import why21 from '../Assets/Images/Why21.png';
import why22 from '../Assets/Images/Why22.png';

import '../Assets/Styles/pages/Home.css';

function Home() {

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1 },
    { width: 768, itemsToShow: 2 },
    { width: 1200, itemsToShow: 3 },
  ];

  function pricing() {
    window.location.href = '/pricing';
  }

  function schedule() {
    window.location.href = '/booking';
  }

  return (
    <div className='row' id='Home'>
      <Helmet>
        <title>Home - Destivict</title>
      </Helmet>
        <header>
          <Nav/>
          <div id='hd-inner'>
            <h1>Protect Your Vehicle and Equipment from Rust Damage with Our Advanced Solutions.</h1>
            <p>Extend your vehicle's lifespan in North Bay, Ontario with our industry-leading anti-rust treatments.</p>
            <button onClick={schedule} style={{backgroundColor: "#B7410E", justifySelf: 'center'}} className="btn">
              <a href="/booking" style={{textDecoration: "none", color: "white", fontWeight: 'bold'}}>Book Now</a>
            </button>
          </div>
        </header>
        <main>
          <section id='section1'>
            <article>
              <h1 style={{color: '#000080'}}>Why Rust Protection Matters for Your Vehicle</h1>  
              <img id='mbimg' alt='' src={rustV}/>
              <div id='listicle'>
                <ol style={{display: 'grid'}}>
                  <li style={{color: '#000080', fontWeight: '600', fontSize: '30px', textAlign: 'left', borderTop: '2px solid black'}}>
                    <span style={{color: 'black', fontWeight: '400', fontSize: '25px', marginLeft: '2%'}}>Prevent structural damage</span>
                    <p style={{color: '#7E8492',  marginLeft: '2%', fontSize: '15px'}}>Rust weakens your vehicle’s frame and critical parts, reducing safety and durability. Rust protection preserves structural integrity.</p>
                  </li>
                  <li style={{color: '#000080', fontWeight: '600', fontSize: '30px', textAlign: 'left', borderTop: '2px solid black'}}>
                    <span style={{color: 'black', fontWeight: '400', fontSize: '25px', marginLeft: '2%'}}>Increase vehicle resale value</span>
                    <p style={{color: '#7E8492',  marginLeft: '2%', fontSize: '15px'}}>A rust-free car looks better and sells for more. Rust protection maintains value and appeal for future buyers.</p>
                  </li>
                  <li style={{color: '#000080', fontWeight: '600', fontSize: '30px', textAlign: 'left', borderTop: '2px solid black'}}>
                    <span style={{color: 'black', fontWeight: '400', fontSize: '25px', marginLeft: '2%'}}>Maintain the safety of your vehicle</span>
                    <p style={{color: '#7E8492',  marginLeft: '2%', fontSize: '15px'}}>Rust can damage essential components like brake and fuel lines, increasing risks. Protecting against rust ensures safety and reliability.</p>
                  </li>
                </ol>
                <img id='dskimg' alt='' src={rustV}/>
              </div>
            </article>        
          </section>
          <section id='section2' style={{backgroundColor: '#3B3838'}}>
            <h1 style={{color: 'white', textAlign: 'left'}}>Our Comprehensive services</h1>
            <section className="slider-container">
                <div className="slider-images pagination">
                    <div id='bk1' className="slider-img active">
                      <div id='spst' style={{backgroundColor: 'rgb(0 0 0 / 72%)', width: '100%', height: '100%', placeContent: 'center', fontFamily: 'Poppins'}}>
                        <h2 style={{fontFamily: 'Roboto Slab'}}>Rust Protection Service</h2>
                      </div>
                        <span>
                          <h2 style={{fontFamily: 'Roboto Slab'}}>Rust Protection Service</h2>
                          <p style={{fontFamily: 'Open Sans'}}> a. Rust Proofing: Application of protective coatings to prevent rust on vehicle bodies and undercarriages.<br/>
                              b. Undercoating: Application of a protective layer to the underbody of vehicles, shielding against moisture and road debris.<br/>
                              c. Touch-Up Treatments: Spot treatments for existing rust areas to prevent further corrosion.
                          </p>
                          <button onClick={pricing} style={{backgroundColor: "#B7410E", padding: '2%', textAlign: 'center'}} className="btn">
                            <a href="/pricing" style={{textDecoration: "none", color: "white", fontWeight: 'bold'}}>See Pricing</a>
                          </button>
                        </span>
                    </div>
                    <div id='bk2' className="slider-img">
                      <div id='spst' style={{backgroundColor: 'rgb(0 0 0 / 72%)', width: '100%', height: '100%', placeContent: 'center', fontFamily: 'Poppins'}}>
                        <h2 style={{fontFamily: 'Roboto Slab'}}>Mobile Fleet Care Service</h2>
                      </div>
                        <span>
                          <h2 style={{fontFamily: 'Roboto Slab'}}>Mobile Fleet Care Service</h2>
                          <p style={{fontFamily: 'Open Sans'}}>a. On-Site Rust Proofing: Mobile rust protection services for fleet vehicles at the client’s location.<br/>
                            b. Preventive Maintenance: Regular inspections and maintenance services for fleet vehicles to identify and address rust early.<br/>
                            c. Fleet Wash and Decontamination: Cleaning services to remove contaminants that can lead to rust.

                          </p>
                          <button onClick={pricing} style={{backgroundColor: "#B7410E", padding: '2%', textAlign: 'center'}} className="btn">
                            <a href="/pricing" style={{textDecoration: "none", color: "white", fontWeight: 'bold'}}>See Pricing</a>
                          </button>
                        </span>
                    </div>
                    <div id='bk3' className="slider-img">
                      <div id='spst' style={{backgroundColor: 'rgb(0 0 0 / 72%)', width: '100%', height: '100%', placeContent: 'center', fontFamily: 'Poppins'}}>
                        <h2 style={{fontFamily: 'Roboto Slab'}}>Tire Change Services</h2>
                      </div>
                        <span>
                          <h2 style={{fontFamily: 'Roboto Slab'}}>Tire Change Services</h2>
                          <p style={{fontFamily: 'Open Sans'}}> Tire replacement and rotation services for vehicles.</p>
                          <button onClick={pricing} style={{backgroundColor: "#B7410E", padding: '2%', textAlign: 'center'}} className="btn">
                            <a href="/pricing" style={{textDecoration: "none", color: "white", fontWeight: 'bold'}}>See Pricing</a>
                          </button>
                        </span>
                    </div>
                    <div id='bk4' className="slider-img">
                      <div id='spst' style={{backgroundColor: 'rgb(0 0 0 / 72%)', width: '100%', height: '100%', placeContent: 'center', fontFamily: 'Poppins'}}>
                        <h2 style={{fontFamily: 'Roboto Slab'}}>Exterior Detailing</h2>
                      </div>
                        <span>
                          <h2 style={{fontFamily: 'Roboto Slab'}}>Exterior Detailing</h2>
                          <p style={{fontFamily: 'Open Sans'}}> Cleaning and protecting the exterior surfaces of vehicles to enhance appearance and longevity.</p>
                          <button onClick={pricing} style={{backgroundColor: "#B7410E", padding: '2%', textAlign: 'center'}} className="btn">
                            <a href="/pricing" style={{textDecoration: "none", color: "white", fontWeight: 'bold'}}>See Pricing</a>
                          </button>
                        </span>
                    </div>
                    <div id='bk5' className="slider-img">
                      <div id='spst' style={{backgroundColor: 'rgb(0 0 0 / 72%)', width: '100%', height: '100%', placeContent: 'center', fontFamily: 'Poppins'}}>
                        <h2 style={{fontFamily: 'Roboto Slab'}}>Desalting Services</h2>
                      </div>
                        <span>
                          <h2 style={{fontFamily: 'Roboto Slab'}}>Desalting Services</h2>
                          <p style={{fontFamily: 'Open Sans'}}>Removal of salt buildup from vehicles, particularly after winter or exposure to salt roads.</p>
                          <button onClick={pricing} style={{backgroundColor: "#B7410E", padding: '2%', textAlign: 'center'}} className="btn">
                            <a href="/pricing" style={{textDecoration: "none", color: "white", fontWeight: 'bold'}}>See Pricing</a>
                          </button>
                        </span>
                    </div>
                </div>
            </section>
          </section>
          <section id='section3'>
            <h1>Why Choose Destivict Solutions Inc.</h1>
            <div>
              <span>
                <img alt='' src={icon1}/>
                <h2>Expertise You Can Trust</h2>
                <p>Over a decade of experience in rust protection and vehicle maintenance.</p>
              </span>
              <span>
                <img alt='' src={icon2}/>
                <h2>Mobile Services</h2>
                <p>We come to you, saving you time and hassle.</p>
              </span>
              <span>
                <img alt='' src={icon3}/>
                <h2>Customer Satisfaction</h2>
                <p>We build long-term relationships with our clients, ensuring every job is done right.</p>
              </span>
            </div>
          </section>
          <section id='section4'>
            <h1>Our Proven Rust Protection Process</h1>
            <Carousel id="mobcaro" breakPoints={breakPoints}>
              <span className="card">
                <div style={{fontFamily: 'Open Sans', color: '#FFFF00'}}>Step 1</div>
                <h2>Inspection</h2>
                <p>Comprehensive vehicle inspection for existing rust.</p>
              </span>
              <span className="card">
                <div style={{fontFamily: 'Open Sans', color: '#FFFF00'}}>Step 2</div>
                <h2>Cleaning</h2>
                <p>Thorough cleaning to remove dirt and contaminants.</p>
              </span>
              <span className="card">
                <div style={{fontFamily: 'Open Sans', color: '#FFFF00'}}>Step 3</div>
                <h2>Application</h2>
                <p>Applying our proprietary rust protection formula to vulnerable areas.</p>
              </span>
              <span className="card">
                <div style={{fontFamily: 'Open Sans', color: '#FFFF00'}}>Step 4</div>
                <h2>Final Inspection</h2>
                <p>Ensuring full coverage and quality of protection.</p>
              </span>
            </Carousel>
            <div id='deskcar'>
              <span>
                <div style={{fontFamily: 'Open Sans', color: '#FFFF00'}}>Step 1</div>
                <h2>Inspection</h2>
                <p>Comprehensive vehicle inspection for existing rust.</p>
              </span>
              <span>
                <div style={{fontFamily: 'Open Sans', color: '#FFFF00'}}>Step 2</div>
                <h2>Cleaning</h2>
                <p>Thorough cleaning to remove dirt and contaminants.</p>
              </span>
              <span>
                <div style={{fontFamily: 'Open Sans', color: '#FFFF00'}}>Step 3</div>
                <h2>Application</h2>
                <p>Applying our proprietary rust protection formula to vulnerable areas.</p>
              </span>
              <span>
                <div style={{fontFamily: 'Open Sans', color: '#FFFF00'}}>Step 4</div>
                <h2>Final Inspection</h2>
                <p>Ensuring full coverage and quality of protection.</p>
              </span>
            </div>
            <button onClick={schedule} id='carbtn' style={{backgroundColor: "#B7410E", padding: '2%', textAlign: 'center', margin: '5%'}} className="btn">
              <a href="/pricing" style={{textDecoration: "none", color: "white", fontWeight: 'bold'}}>Schedule Your Rust Protection Today</a>
            </button>
          </section>
          <section id="section5">
            <p>TRUSTED BY 2000+ Customers</p>
            <h1>What Our Customers Are Saying</h1>
            <div id='deskcar2'>
              <span>
                <img alt='' src={why21}/>
                <p>Destivict Solutions saved my truck from rust damage, and the results were incredible!
                  <span style={{color: '#FFFF00'}}>John D.</span>
                </p>
              </span>
              <span>
                <img alt='' src={why22}/>
                <p>I was impressed with their professionalism and the detailed rust protection process. Highly recommend.
                  <span style={{color: '#FFFF00'}}>Sarah M.</span>
                </p>
              </span>
            </div>
            <Carousel id="mobcaro2" breakPoints={breakPoints}>
              <span className='card'>
                <img alt='' src={why21}/>
                <p style={{color: '#FFFFFF'}}>Destivict Solutions saved my truck from rust damage, and the results were incredible!</p>
                <p style={{color: '#FFFF00'}}>John D.</p>
              </span>
              <span className='card'>
                <img alt='' src={why22}/>
                <p style={{color: '#FFFFFF'}}>I was impressed with their professionalism and the detailed rust protection process. Highly recommend.</p>
                <p style={{color: '#FFFF00'}}>Sarah M.</p>
              </span>
            </Carousel>
          </section>
          <section id='section6'>
            <h1>Frequently Asked Questions</h1>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    What vehicles and equipment do you specialize in rust protection for?
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    We handle everything from personal vehicles to industrial fleets, government vehicles, and agricultural equipment.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    How does rust-proofing benefit my vehicle or equipment?
                  </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                  Rust-proofing protects against corrosion, extends lifespan, reduces maintenance costs, and maintains value over time.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Do you offer mobile services for fleet care?
                  </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Yes, we provide on-site rust-proofing, undercoating, and fleet washing services to minimize downtime for fleet owners.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    What’s included in your rust protection services?
                  </button>
                </h2>
                <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Our services include full rust-proofing, undercoating for the undercarriage, and preventive maintenance to combat corrosion.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFive">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    Are your rust-proofing products environmentally friendly?
                  </button>
                </h2>
                <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Absolutely. We use eco-conscious materials and methods to ensure effective protection with minimal environmental impact.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingSix">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                    How often should I schedule rust-proofing for my vehicle?
                  </button>
                </h2>
                <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Annual rust-proofing is recommended, especially in areas with harsh weather conditions like North Bay.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingSeven">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                    Can rust-proofing be applied to older vehicles?
                  </button>
                </h2>
                <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Yes, rust-proofing can help prevent further corrosion on older vehicles and protect remaining structural integrity.
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Contsec/>
          <Getsec/>
        </main>
        <Footer/>
    </div>
  )
}

export default Home;