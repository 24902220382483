import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import {Helmet} from "react-helmet";
import { useForm } from "react-hook-form";

import Nav from '../components/Nav';
import Footer from '../components/Footer';

import '../Assets/Styles/pages/Contact.css';

function Contact() {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [topic, setTopic] = useState('');
  const [message, setMessage] = useState('');

  const [conf, setConf] = useState(false)
  const form = useRef();

  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const mailSender = (e) => {

    const templateParams = {
      name: name,
      email: email,
      topic: topic,
      message: message,
    };
  
    emailjs.sendForm(process.env.REACT_APP_contact_Service_ID, process.env.REACT_APP_contact_Template_ID, form.current, {
      publicKey: process.env.REACT_APP_contact_Public_Key,
    })
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      setConf(true)
      /* setTimeout(() => {
        setConf(true)
      }, 250) */
      if (response.status == 200) {
        reset();
      }
    },
    (err) => {console.log('FAILED...', err);}
  )};

  return (
    <div className='row' id='Contact'>
      <Helmet>
        <title>Contact Us - Destivict</title>
      </Helmet>
        <header>
          <Nav/>
          <div id='hd-inner'>
            <h1>Contact us</h1>
            <p>We're here to answer your questions, assist with any inquiries, and provide the support you need. Reach out to ustoday!</p>
          </div>
        </header>
        <span id='mss' style={{placeItems: 'center'}}>
            <form ref={form} style={{display: 'grid', textAlign: 'start'}} onSubmit={handleSubmit(mailSender)}>
                <label>Your Name</label>
                <input type='text' onChange={e => setName(e.target.value)} {...register("name", { required: true, maxLength: 50 })}/>
                {errors.name ? <p id="error">Please input a Name</p> : ""}
                <label>Your Email</label>
                <input type='email' onChange={e => setEmail(e.target.value)} {...register("email", { required: true, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}/>
                {errors.email ? <p id="error">Please input a valid Email address</p> : ""}
                <label>Topic</label>
                <input type='' onChange={e => setTopic(e.target.value)} {...register("topic", { required: true, maxLength: 40 })} placeholder='What is your reason for reaching out'/>
                {errors.topic ? <p id="error">Please input a Topic</p> : ""}
                <label>Message</label>
                <textarea placeholder='Write your message' onChange={e => setMessage(e.target.value)} rows='5' cols='50' {...register("message", { required: true, maxLength: 1001 })}></textarea>
                {errors.message ? <p id="error">Please input a message</p> : ""}
                <input id='formconf' type="text" style={{display: (conf === true ? 'flex' : "none"), color: "blue", fontWeight: "700", fontSize: "20px", border: "0px"}} value="Message sent successfully"/>
                <button style={{placeSelf: 'start', display: 'grid', backgroundColor: "#B7410E", marginTop: '4%', padding: '2%', justifySelf: 'center', color: "white", fontWeight: 'bold'}} className="btn">Send Message</button>
            </form>
        </span>
        <Footer/>
    </div>
  )
}

export default Contact;