import React from 'react'
import Nav from '../components/Nav';
import Footer from '../components/Footer';

import { Helmet } from 'react-helmet';

import '../Assets/Styles/pages/404.css';
import '../Assets/Styles/pages/Home.css';

function Error() {
  return (
     <div className='row' id='Home'>
          <Helmet>
               <title>Error - Destivict</title>
          </Helmet>
          <header>
               <Nav/>
               <div id='hd-inner'></div>
        </header>
        <main>
          <p id='errtxt'>The page you're looking for does not exists, go to the <a href='/'>homepage</a> or route to any other specific page from the menu navigation.</p>
        </main>
        <Footer/>
    </div>
  )
}

export default Error;